<template>
<v-card :class="customClass">
    <v-row class="mb-1" v-if="title !== 'N/A'">
      <v-col>
        <span class="transaction-label">{{title}}</span>
      </v-col>
    </v-row>
    <div v-if="list">
        <v-row class="ml-0 mr-3">
            <div v-if="selectedPaymentMethod && listType === 'RADIO'" style="width: 100%; border: 0; padding: 0; margin: 0;">
              <v-radio-group v-model="selectedAccountNumber" column>
                <template v-slot:label>
                  <div>Select Account</div>
                </template>
                <v-radio :value="item" v-for="(item, index) in list" :key="`item-${index}`">
                    <template v-slot:label>
                       <v-row style="margin: 3px 0">
                        <v-col cols="7" class="breakdown-label">{{getPaymentOption(item)}}</v-col>
                        <v-col cols="5" class="breakdown-value" align="end">
                            <div justify="end" v-if="amount">
                                {{`${getAmount(amount[index])} ${getAmountLabel(item)}`}}
                            </div>
                            <div justify="end" v-else>
                                {{`${getAmount(item)} ${getAmountLabel(item)}`}}
                            </div>
                        </v-col>
                       </v-row>
                    </template>
                  </v-radio>
              </v-radio-group>
            </div>
            <div v-else class="fill-height" style="padding: 2px 0; width: 100%">
              <v-row v-for="(item, index) in list" :key="`item-${index}`" style="margin: 3px 0">
                <v-col cols="12">
                  <v-row class="mb-1 ml-3">
                      <v-col cols="7" class="breakdown-label">{{getPaymentOption(item)}}:</v-col>
                      <v-col cols="5" class="breakdown-value" align="end">
                        <div justify="end" v-if="amount">
                          {{`${getAmount(amount[index])} ${getAmountLabel(item)}`}}
                        </div>
                        <div justify="end" v-else>
                          {{`${getAmount(item)} ${getAmountLabel(item)}`}}
                        </div>
                      </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="inputs" cols="12">
                    <v-row class="mb-1 ml-3">
                      <v-col>
                        <span class="transaction-label">Please input account number</span>
                      </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in inputs" :key="`inputs-${index}`" style="margin: 30px 30px">
                    <v-text-field outlined :label="item.name" v-model="form[item.key]"></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
            </div>
        </v-row>
    </div>
    <v-row align="center" justify="center" v-if="button">
        <v-col align="center" justify="center">
            <v-btn class="mr-4 mt-1 mb-1" @click="submit" color="blue" dark>
                {{buttonLabel ? buttonLabel: 'Confirm Payment'}}
            </v-btn>
        </v-col>
    </v-row>
</v-card>
</template>

<script>
import {
    splitCamelCaseToString,
    getAmountLabel,
    formatAmount,
    getPaymentMethodName
} from '@/utils/params.js';

export default {
    name: 'BreakDown',
    props: ['customClass', 'title', 'list', 'amount', 'button', 'merchants', 'data', 'selectedPaymentMethod', 'buttonLabel', 'listType', 'inputs'],
    data: () => ({
      selectedAccountNumber: undefined,
      form: {}
    }),
    methods: {
        getPaymentOption(item) {
            const code = item.key ?? item
            try {
              if(this.selectedPaymentMethod) {
                if(item.key) {
                  return item.key
                }
                return this.selectedPaymentMethod.name
              } else {
                const name = getPaymentMethodName(code, this.merchants[this.data.MC].paymentMethods)
                return name
              }
            } catch(error) {
              console.log(error)
            }
            return splitCamelCaseToString(code)
        },
        getAmountLabel(item) {
          if(this.getAmount(item) === "") {
            return ""
          }
            if (item.key) {
              if(this.selectedPaymentMethod) {
                return this.selectedPaymentMethod.label
              } else {
                return getAmountLabel(this.data.MC, item.key, this.merchants)
              }
            } else {
              if(this.selectedPaymentMethod) {
                return this.selectedPaymentMethod.label
              } else {
                return getAmountLabel(this.data.MC, item, this.merchants)
              }
            }
        },
        getAmount(item) {
            if (item.value) {
                return Number.isNaN(parseFloat(formatAmount(item.value))) ? "" :formatAmount(item.value)
            } else {
                return Number.isNaN(parseFloat(formatAmount(item))) ? "" :formatAmount(item)
            }
        },
        submit() {
          console.log(this.selectedPaymentMethod, this.listType, this.selectedAccountNumber)
          if(this.selectedPaymentMethod && this.listType === 'RADIO') {
            console.log('pass selected account number')
            this.button(this.selectedAccountNumber)
          } else if(this.inputs && this.inputs.length > 0 ) {
            if(this.form && this.form.accountNumber){
              this.button({"key":this.form.accountNumber, "value": "N/A"}, this.form)
            } else {
              window.alert("Account number is required!")
            }
          } else {
            this.button({"key":this.data.mobile, "value": "N/A"})
          }
        }
    }
};
</script>

<style scoped>
.transaction-label {
    font-weight: bold;
    font-size: 1em;
}

.transaction-title {
    font-weight: 500;
    font-size: 1.2em;
    text-align: center;
}

.breakdown-label {
    font-weight: 400;
    font-size: 0.8em;
    color: black;
}

.breakdown-value {
    font-weight: 400;
    font-size: 0.8em;
    color: black;
}
</style>
