<template>
<v-app>
    <v-main style="padding: 0;">
        <v-container fluid>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <loading-overlay :loading="loading" />
              </v-col>
            </v-row>
            <div v-if="isVerified && !loading">
                <md-dialog-alert :md-active.sync="isDisplayDialog" :md-content="dialog.dialogMessage" :md-title="dialog.dialogTitle" md-confirm-text="OK" @md-closed="closeDialog" />
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center">
                      <span class="transaction-title">
                          You are being charged by
                      </span>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <v-img :src="merchantLogo" @load="imageLoaded" alt="merchant logo" contain max-height="85" />
                    <span v-if="!isImageLoaded" class="transaction-title">
                      {{getMerchantname()}}
                    </span>
                  </v-col>
                </v-row>
                <div v-if="customerData && customerData.description">
                    <v-row>
                      <v-col>
                        <span class="transaction-label">Description:</span>
                        <span class="breakdown-value ml-3">{{customerData ? customerData.description: '-'}}</span>
                      </v-col>
                    </v-row>
                </div>
                <v-row>
                  <v-col>
                    <span class="transaction-label">Transaction Total Amount:</span>
                    <span class="breakdown-value ml-3">{{customerData ? '₱' + getPayableAmount(customerData.amount) : '-'}}</span>
                  </v-col>
                </v-row>
                <v-radio-group v-model="selectedPaymentOption" column @change="selectPaymentOption">
                  <template v-slot:label>
                    <v-row justify="space-around">
                      <v-col :cols="selectedPaymentOption ? 10 : 12">Select a Payment Method</v-col>
                      <v-col v-if="selectedPaymentOption">
                        <v-icon medium color="blue darken-2" @click="resetPaymentOption">
                          mdi-restart
                        </v-icon>
                      </v-col>
                    </v-row>
                  </template>
                  <div v-for="(option, index) in paymentOptionList" :key="`option-${index}`" style="margin: 2px 2px">
                    <v-radio :value="option" v-if="selectedPaymentOption && selectedPaymentOption.code === option.code || !selectedPaymentOption">
                      <template v-slot:label>
                        <v-row justify="space-between">
                          <v-col cols="7"> {{option.name}}
                          </v-col>
                          <v-col cols="5" class="right-align"> {{getPayableAmount(option.amountToPay)}} {{option.label}}
                          </v-col>
                        </v-row>
                      </template>
                    </v-radio>
                    <div v-if="selectedPaymentOption && selectedPaymentOption.code === option.code">
                      <v-container v-if="!hasBalanceComputation">
                        <v-row
                          class="fill-height"
                          align-content="center"
                          justify="center"
                        >
                          <v-col
                            class="text-subtitle-1 text-center"
                            cols="12"
                          >
                            {{option.code == 'PDC' ? "Generating payment option's list" : "Computing your balance"}}
                          </v-col>
                          <v-col cols="6">
                            <v-progress-linear
                              color="deep-purple accent-4"
                              indeterminate
                              rounded
                              height="6"
                            ></v-progress-linear>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container v-else>
                        <div v-if="option.code == 'PDC'">
                          <v-row align="center" justify="center" v-if="!hasPaymentIntent">
                              <v-col align="center" justify="center">
                                  <v-btn class="mr-4 mt-1 mb-1" @click="submit" color="blue" dark>
                                      RETRY
                                  </v-btn>
                              </v-col>
                          </v-row>
                          <CardForm v-else
                            :form-data="formData"
                            @input-card-number="updateCardNumber"
                            @input-card-name="updateCardName"
                            @input-card-month="updateCardMonth"
                            @input-card-year="updateCardYear"
                            @input-card-cvv="updateCardCvv"
                          />
                        </div>
                        <div v-else-if="option.code == 'GCASH'">
                          <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="[selectedPaymentOption]" :amount="[selectedPaymentOption.amountToPay]" :selected-payment-method="selectedPaymentOption" :data="customerData" button-label="Continue" :button="submit"/>
                        </div>
                        <div v-else-if="option.code == 'landbank'">
                          <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="[selectedPaymentOption]" :amount="[selectedPaymentOption.amountToPay]" :selected-payment-method="selectedPaymentOption" :data="customerData"/>
                          <break-down class="mt-4 pa-2" title="N/A" :list="balanceBreakdown" :selected-payment-method="selectedPaymentOption" :data="customerData" list-type="RADIO" button-label="Continue" :button="submit"/>
                        </div>
                        <div v-else>
                          <break-down class="mt-4 pa-2" title="Amount to Pay:" :list="[selectedPaymentOption]" :amount="[selectedPaymentOption.amountToPay]" :selected-payment-method="selectedPaymentOption" :data="customerData"/>
                          <break-down class="mt-1 pa-2" :title="`Balance of ${customerData.mobile}:`" :list="balanceBreakdown" :selected-payment-method="selectedPaymentOption" :data="customerData" list-type="RADIO" button-label="Continue" :button="submit"/>
                        </div>
                      </v-container>
                    </div>
                  </div>
                </v-radio-group>
            </div>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import LoadingOverlay from '@/views/component/LoadingOverlay.vue';
import BreakDown from '@/views/component/BreakDown.vue';
import API from '@/api/API'
import {encode, decode} from '@/utils/base64.js'
import CardForm from '@/views/component/CardForm.vue'

import {
    mapGetters
} from 'vuex';
import {
    invalidParameterErrorCode,
    isValidOptionParameter,
    splitCamelCaseToString,
    formatAmount,
    getBalanceV2Parameters,
    getBalanceBreakdownV2,
    getBalanceBreakdownWithProvidedAN,
} from '@/utils/params.js';

export default {
    name: 'Options',
    components: {
        LoadingOverlay,
        BreakDown,
        CardForm,
    },
    computed: {
        ...mapGetters([
            'customerData'
        ])
    },
    data: () => ({
        loading: true,
        isVerified: false,
        paymentOptionList: [],
        landbankInput: [],
        merchantLogo: undefined,
        selectedPaymentOption: undefined,
        balanceBreakdown: [],
        isDisplayDialog: false,
        isImageLoaded: false,
        hasBalanceComputation: false,
        hasPaymentIntent: false,
        dialog: {
            dialogMessage: '',
            dialogTitle: '',
            redirectUrl: ''
        },
        formData: {
          cardName: '',
          cardNumber: '',
          cardMonth: '',
          cardYear: '',
          cardCvv: ''
        },
        paymentIntent: {}
    }),
    mounted() {
        this.$store.dispatch('NavbarState', {state: false});
        this.$i18n.locale = navigator.language
        this.verify()
    },
    methods: {
        resetPaymentOption() {
          this.selectedPaymentOption = undefined
        },
        async verify() {
          console.log('verifying parameters...')
            this.isVerified = false
            this.loading = true
            if (isValidOptionParameter(this.customerData)) {
                //get list of payment options for this merchant
                this.getPaymentOptions()
            } else {
              const errorCode = invalidParameterErrorCode(this.customerData, "OPTIONS")
              console.log('401: Invalid parameters - ' + errorCode)
              this.$router.replace({path:'401', query: { errorCode: errorCode }})
            }
        },
        async getPaymentOptions() {
          const response = await API.getPaymentOptions(this.customerData)
          setTimeout(() => {
            if(response.paymentOptions) {
              this.paymentOptionList = response.paymentOptions
              this.merchantLogo = response.logo
              this.isVerified = true
              this.loading = false
            } else {
              console.log("Payment option error: ", response.error)
              console.log("Payment option error message", JSON.stringify(response.error.message ?? ""))
              this.loading = false
              if((response.error.message ?? response.error).includes("Not Found")) {
                this.$router.replace({path:'404', query: { errorCode: '20001' }})
              } else if(response.error.message === "Network Error") {
                this.$router.replace({path:'404', query: { errorCode: '20002' }})
              } else if(response.error.includes("Bad Gateway")) {
                this.$router.replace({path:'500', query: { errorCode: '20003' }})
              } else if((response.error.message ?? response.error).includes("Gateway Time-out")) {
                this.$router.replace({path:'500', query: { errorCode: '20004' }})
              } else {
                this.$router.replace({path:'401', query: { errorCode: '10200' }})
              }
            }
          }, 2000)
        },
        back() {
            this.$router.back()
        },
        async selectPaymentOption() {
          this.hasBalanceComputation = false
          if(this.selectedPaymentOption.code === 'PDC') {
            if(!this.hasPaymentIntent) {
              //get paymongo payment intent
              const params = {
                amount: this.selectedPaymentOption.amountToPay
              }
              const response = await API.getPaymongoPaymentIntent(params)
              console.log(response)
              if(response.statusCode == "200") {
                this.hasBalanceComputation = true
                this.hasPaymentIntent = true
                this.paymentIntent = response
              } else {
                this.dialog = {
                  dialogTitle: "",
                  dialogMessage: `${response ? response.error ?? response : "Error"}`
                }
                this.hasBalanceComputation = true
                this.isDisplayDialog = true
              }
            } else {
              this.hasBalanceComputation = true
            }
          } else if(this.selectedPaymentOption.code === 'GCASH') {
            this.hasBalanceComputation = true
          } else if(this.selectedPaymentOption.code === 'landbank') {
            this.hasBalanceComputation = true
            this.balanceBreakdown = getBalanceBreakdownWithProvidedAN(this.customerData.AN)
            console.log("LANDBANK")
          } else {
            console.log("ELSE")
            //call account and balance api
            const params = getBalanceV2Parameters(this.customerData, this.selectedPaymentOption)
            const response = await API.getBalanceV2(params)
            setTimeout(() => {
              if(response.statusCode == "200") {
                const accounts = response.resultDetails
                this.balanceBreakdown = getBalanceBreakdownV2(response.resultDetails, this.selectedPaymentOption)
                this.hasBalanceComputation = true
              } else {
                console.log("Payment balance error: ", response.error)
                console.log("Payment balance error message", JSON.stringify(response.error.message ?? ""))
                this.hasBalanceComputation = true
                if((response.error.message ?? response.error).includes("Not Found")) {
                  this.$router.replace({path:'404', query: { errorCode: '20001' }})
                } else if(response.error.message === "Network Error") {
                  this.$router.replace({path:'404', query: { errorCode: '20002' }})
                } else if(response.error.includes("Bad Gateway")) {
                  this.$router.replace({path:'500', query: { errorCode: '20003' }})
                } else if((response.error.message ?? response.error).includes("Gateway Time-out")) {
                  this.$router.replace({path:'500', query: { errorCode: '20004' }})
                } else {
                  this.$router.replace({path:'401', query: { errorCode: '10200' }})
                }
              }
            }, 2000)
          }
        },
        closeDialog() {
          this.isDisplayDialog = false
        },
        getMerchantname() {
          return this.customerData ? splitCamelCaseToString(this.customerData.MC): '-'
        },
        getPayableAmount(amount) {
          return formatAmount(amount)
        },
        imageLoaded() {
          this.isImageLoaded = true
        },
        submit(selectedAccountNumber) {
          if(selectedAccountNumber) {
            //check balance vs payable amount
            if(selectedAccountNumber.value == "N/A" || selectedAccountNumber.value >= this.selectedPaymentOption.amountToPay) {
              console.log('notify merchant to continue transaction')
              //send the notification for embedding iframe of merchant
              const postNotification = {
                data: this.customerData,
                paymentMethod: this.selectedPaymentOption,
                account: selectedAccountNumber,
                token:  btoa(encode(
                  this.selectedPaymentOption.code + '|' +
                  this.selectedPaymentOption.name + '|' +
                  this.selectedPaymentOption.amountToPay + '|' +
                  this.selectedPaymentOption.label + '|' +
                  selectedAccountNumber.key + '|' +
                  this.customerData.MC + '|' +
                  this.customerData.amount+ '|' +
                  this.customerData.timestamp))
              }
              console.log(postNotification)
              window.parent.postMessage(JSON.stringify(postNotification), '*')
              //TODO: FOR TESTING WITH IFRAME
            } else {
              console.log("not enough balance")
              this.dialog = {
                dialogTitle: "Insufficient Balance",
                dialogMessage: `${this.selectedPaymentOption.name} account ${selectedAccountNumber.key} does not have enough balance to pay the payable amount of ${this.selectedPaymentOption.amountToPay} ${this.selectedPaymentOption.label}.`
              }
              this.isDisplayDialog = true
            }
          } else {
            console.log("did not select an account")
              this.dialog = {
                dialogTitle: "Error",
                dialogMessage: `Please select an account for payment before you may continue.`
              }
              this.isDisplayDialog = true
          }
        },
        updateCardNumber (val) {
          console.log(val)
        },
        updateCardName (val) {
          console.log(val)
        },
        updateCardMonth (val) {
          console.log(val)
        },
        updateCardYear (val) {
          console.log(val)
        },
        updateCardCvv (val) {
          console.log(val)
        }
    }
};
</script>

<style scoped>
.transaction-label {
    font-weight: bold;
    font-size: 1em;
}

.transaction-title {
    font-weight: 500;
    font-size: 1.2em;
    text-align: center;
}

.breakdown-label {
    font-weight: 400;
    font-size: 1em;
    color: black;
}

.breakdown-value {
    font-weight: 400;
    font-size: 1em;
    color: black;
}

.right-align {
  text-align: right;
}
</style>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");


body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 200px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg)
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
</style>
